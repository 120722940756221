<script>
    import { getContext, onMount } from 'svelte';
    import { inertia } from '@inertiajs/svelte';
    const settingsContext = getContext('settingsContext');
    import Dropzone from 'dropzone';
    import './settings.css';
    const { authUser, data } = settingsContext;

    let myDropzone = null;
    let uploadedFiles = [];
    const initUploader = () => {
        const selector = '.dropzone';
        // Check if Dropzone is already attached to the element
        if (!document.querySelector(selector).classList.contains('dz-clickable')) {
            myDropzone = new Dropzone(selector, {
                url: `${app.baseUrl}/my/settings/verify/upload`,
                paramName: 'file',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
                maxFilesize: mediaSettings.max_file_upload_size,
                addRemoveLinks: true,
                dictRemoveFile: 'x',
                acceptedFiles: mediaSettings.allowed_file_extensions,
                dictDefaultMessage: trans('Drop files here to upload'),
                autoDiscover: false,
                previewsContainer: '.dropzone-previews',
                autoProcessQueue: true,
                parallelUploads: 1,
            });

            myDropzone.on('addedfile', (file) => {
                updatePreviewElement(file, true);
            });

            myDropzone.on('success', (file, response) => {
                uploadedFiles.push(response.assetSrc);
                file.upload.assetSrc = response.assetSrc;
            });

            myDropzone.on('removedfile', function (file) {
                removeAsset(file.upload.assetSrc);
            });

            myDropzone.on('error', (file, errorMessage) => {
                if (typeof errorMessage.errors !== 'undefined') {
                    Object.values(errorMessage.errors).forEach((error) => {
                        launchToast('danger', trans('Error'), error);
                    });
                } else {
                    const message = errorMessage.message || errorMessage;
                    launchToast('danger', trans('Error'), message);
                }
                myDropzone.removeFile(file);
            });
        }
    };

    const removeAsset = (file) => {
        if (!submitTab) {
            fetch(`${app.baseUrl}/my/settings/verify/upload/delete`, {
                method: 'POST',
                body: JSON.stringify({ assetSrc: file }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
            })
                .then((response) => {
                    if (response.ok) {
                        launchToast('success', trans('Success'), trans('Attachment removed.'));
                    } else {
                        launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                    }
                })
                .catch((error) => {
                    console.error('Error removing asset:', error);
                    launchToast('danger', trans('Error'), trans('Failed to remove the attachment.'));
                });
        }
    };

    const updatePreviewElement = (file, isAdded) => {
        // Implement this function if needed
    };

    onMount(() => {
        initUploader();
    });
</script>

<svelte:head>
    <link rel="stylesheet" href="https://unpkg.com/dropzone@5/dist/min/dropzone.min.css" type="text/css" />
</svelte:head>

<div class="verifyUi">
    <!-- @if (!Auth::user()->vipVerification || Auth::user()->vipVerification->status == 'rejected') -->
    {#if !data.authVipVerification || data.authVipVerification.status == 'rejected'}
        {#if data.authVipVerification && data.authVipVerification.status == 'rejected' && data.authVipVerificationupdatedAt <= data.vip_coldown_period}
            <div class="alert alert-warning font-weight-bold mt-2 text-white" role="alert">
                You need to wait
                {data.vip_coldown_period - data.authVipVerificationupdatedAt}
                day{data.vip_coldown_period - data.authVipVerificationupdatedAt ? '' : 's'}
                before you can submit a new vip verification
            </div>
        {:else}
            <div class="form-group">
                <label for="name">Private communication channel</label>
                <input
                    class="form-control"
                    id="communication_channel"
                    name="communication_channel"
                    aria-describedby="emailHelp"
                    value=""
                    placeholder="e.g WhatsApp, Telegram, Twitter DMs, ..."
                />
            </div>
            <div class="form-group">
                <label for="name">ID of communication channel</label>
                <input
                    class="form-control"
                    id="communication_channel_id"
                    name="communication_channel_id"
                    aria-describedby="emailHelp"
                    value=""
                    placeholder="e.g +433463569576,  elia on telegram, @elialol, ..."
                />
            </div>
            <h5 class="">Upload A personal presentation video</h5>
            <h6 class="">Mention that you are currently on the platform</h6>
            <div class="dropzone-previews dropzone w-100 ppl-0 rounded border pb-1 pr-0 pt-1"></div>
            <button
                class="btn btn-block deposit-continue-btn mr-0 mt-4 cursor-pointer rounded-lg !bg-bmn-500 text-xl text-white hover:!bg-bmn-600 disabled:cursor-not-allowed"
                type="submit"
                id="submitVipVerification"
            >
                Apply
            </button>
        {/if}
    {:else if data.authVipVerification && data.authVipVerification.status == 'pending'}
        <div class="alert alert-warning font-weight-bold mt-2 text-white" role="alert">
            Your verification request is pending
        </div>
    {:else if data.authVipVerification && data.authVipVerification.status == 'verified'}
        <div class="alert alert-success font-weight-bold mt-2 text-white" role="alert">
            Your verification request is approved
        </div>
    {/if}
</div>
